@import "styles/_theme-vektklubb.scss";
@import "../SearchAllFoodstuffList.scss";

.resultItem {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  min-height: 5rem;

  .switch {
    margin-top: auto;
    margin-bottom: auto;
  }

  @include search-list-row;
}
