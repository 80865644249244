@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";

.inputLabel {
  @include font-semi-bold;

  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  width: 100%;

  &:last-child {
    margin: 0;
    white-space: nowrap;
  }
}

.inputSmall {
  max-width: 15.8rem;
}

.errors {
  color: red;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 0.4rem;
  white-space: nowrap;
}

.inputClassName:focus::placeholder {
  color: transparent;
}
