@import "styles/_theme-vektklubb.scss";
@import "../SearchAllFoodstuffList.scss";

.headerItem {
  @include search-list-row;

  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  background-color: $color-core-primary-light-3;
}
