@import "styles/_theme-vektklubb.scss";
.lock {
  overflow: hidden;

  @media print {
    overflow: auto;
    position: static;
  }
}

:global .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
