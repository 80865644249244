@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/buttons";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";

.container {
  margin-top: 5rem;
}

.titleWrapper {
  text-align: center;
  padding: 0 2rem;
  width: 100%;

  @include breakpoint-medium-up {
    padding: 0 8rem;
  }

  @include breakpoint-large-up {
    width: 96.8rem;
    margin: 0 auto;
    padding: 0;
  }

  @include breakpoint-larger-up {
    width: $desktop-max-width;
  }
}

.title {
  @include font-bold;

  color: inherit;
  font-size: 2.4rem;
  line-height: 3.4rem;
  margin: 0 0 0.5rem;

  @include breakpoint-medium-up {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }

  @include breakpoint-large-up {
    font-size: 4.8rem;
    line-height: 5.4rem;
  }
}

.description {
  color: inherit;
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 0;

  @include breakpoint-medium-up {
    font-size: 2.1rem;
    line-height: 2.9rem;
  }
}

.bulletContainer {
  max-width: 70rem;
  margin: 0 auto;
  padding: 4rem 3rem 3rem;
}

.list {
  margin: 0 auto;
  list-style: none;
  padding: 0;
  text-align: center;
}

.changeAccountQuestion {
  margin-right: 0.6rem;
}

.changeUserBtn {
  @include button-reset;
  @include a;

  font-size: 1.6rem;
}
