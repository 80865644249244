@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/variables/transitions";
@import "~@styles/utils/mixins/accessibility";
@import "~@styles/utils/mixins/buttons";

.container {
  @include button-reset;

  transition:
    background-color $transition-duration-fastest ease-out,
    border-color $transition-duration-fastest ease-out,
    fill $transition-duration-fastest ease-out;
  background-color: $color-grey-dark;
  border: 0.1rem solid $color-grey-dark;
  border-radius: 50%;
  color: white;
  fill: white;
  width: 2rem;
  height: 2rem;

  &__active {
    border-color: $color-grey-darker;
    background-color: $color-grey-darker;
  }

  &__disabled {
    @include button-disabled;

    border-color: $color-button-primary-background-disabled;
    color: $color-button-primary-contents-disabled;
    background-color: $color-button-primary-background-disabled;
    fill: $color-button-primary-contents-disabled;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.visually-hidden {
  @include visually-hidden;
}
