@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/images";

.container {
  display: flex;
  position: relative;
  min-height: 475px;
  padding-top: 22.6rem;
  padding-bottom: 2rem;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour;
    width: 100%;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    min-height: 52rem;
  }

  &.isHigher {
    @include breakpoint-medium-up {
      min-height: 47.5rem;
    }

    @include breakpoint-larger-up {
      min-height: 65rem;
    }
  }
}

.imageWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.image {
  @include image-in-placeholder;

  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

.boxWrapper {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  align-items: center;
  padding: 0 $spacing-sixteen;
  margin: 0 auto;

  @include breakpoint-medium-up {
    padding: 0;
    max-width: none;
  }

  @include breakpoint-large-up {
    margin: 0 auto;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
  }
}

.box {
  display: flex;
  flex-direction: column;
  max-width: 38rem;
  margin: 0 auto;
  padding: 0 $spacing-sixteen $spacing-sixteen;
  background-color: $color-core-white;
  border-radius: 2rem;

  @include breakpoint-medium-up {
    margin: 0;
    max-width: initial;
    width: 50%;
    padding-left: $spacing-sixteen;
    padding-right: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    border-radius: 3rem;
    padding-left: $spacing-thirtytwo;
    padding-right: $spacing-thirtytwo;
    width: calc(50% - $spacing-thirtytwo);
    margin: $spacing-sixteen 0;
  }

  @include breakpoint-larger-up {
    margin: 0;
  }
}

.content {
  display: block;
  position: relative;
  width: 100%;
  padding: $spacing-thirtytwo 0 0;
  color: $color-text-primary;

  @include breakpoint-medium-up {
    padding: $spacing-thirtytwo $spacing-eight $spacing-eight;
  }

  @include breakpoint-large-up {
    padding: $spacing-fortyeight 0 $spacing-twentyfour;
  }

  .isHigher & {
    @include breakpoint-larger-up {
      padding-top: $spacing-fortyeight;
    }
  }
}

.header {
  @include title-35;

  margin: 0 0 $spacing-eight;

  @include breakpoint-medium-up {
    @include title-40;

    margin-bottom: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    @include title-48;

    margin-bottom: 1.1rem;
  }

  @include breakpoint-larger-up {
    margin-bottom: $spacing-sixteen;
  }
}

.body {
  font-size: 1.6rem;
  line-height: 2.5rem;

  strong {
    font-weight: 600;
  }

  p {
    margin-bottom: $spacing-sixteen;
    font-size: inherit;
  }

  @include breakpoint-medium-up {
    p {
      font-size: 1.8rem;
      margin-bottom: 1.6rem;
    }
  }

  @include breakpoint-larger-up {
    p {
      margin-bottom: 2.4rem;
    }
  }

  .isHigher & {
    @include breakpoint-larger-up {
      font-size: 2rem;
    }
  }
}

.cta {
  @include text-16-semi-bold;

  align-self: center;
  padding: $spacing-twelve 11.2rem;
  margin-bottom: $spacing-sixteen;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.25);

  @include breakpoint-large-up {
    @include text-20-semi-bold;

    padding: $spacing-sixteen 11.2rem;
    margin-bottom: $spacing-sixteen;
  }

  @include breakpoint-larger-up {
    padding: $spacing-sixteen $spacing-fortyeight;
    margin-bottom: $spacing-eight;
  }

  &--redesign {
    background-color: $color-button-primary-background-default;
    color: $color-button-primary-contents-default;
    border: none;

    &:hover {
      background-color: $color-button-primary-background-hovered;
      color: $color-button-primary-contents-default;
      border: none;
    }

    &__active {
      background-color: $color-button-primary-background-pressed;
    }
  }
}

.footNote {
  font-size: 1.1rem;

  @include breakpoint-medium-up {
    margin-top: $spacing-eight;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
