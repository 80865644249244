@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";

@mixin container-title {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }
}

.underline {
  @include underline;
}

.section-teaser-container {
  @include breakpoint-large-up {
    &:nth-child(odd) {
      .articles-groups {
        flex-direction: row;
      }
    }

    &:nth-child(even) {
      .articles-groups {
        flex-direction: row-reverse;
      }
    }
  }
}

.articles-groups {
  display: flex;
  flex-direction: column;
  gap: $spacing-thirtytwo;
}

.first-article-container {
  @include breakpoint-large-up {
    width: 50%;
  }
}

.rest-of-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sixteen;

  @include breakpoint-medium-up {
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    width: 50%;
  }
}

.header-container {
  @include container-title;
}

.section-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $spacing-twentyfour;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-thirtytwo;
  }

  @include breakpoint-large-up {
    margin-bottom: $spacing-forty;
  }
}

.section-title {
  @include responsive-title-4;
}

.navigate-to-article-list-button-container {
  margin: 0.5rem 0;
}

.navigate-to-article-list-button {
  margin: 0;
  padding: 0.4rem 1.6rem;

  @include breakpoint-medium-up {
    padding: 1rem 2.4rem;
  }
}
