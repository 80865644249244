@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";

.container {
  padding: 2.5rem 2rem;

  @include breakpoint-medium-up {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  @include breakpoint-larger-up {
    width: 1170px;
    margin: 0 auto;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin-top: 0;

  @include breakpoint-medium-up {
    column-count: 2;
  }
}

.headline {
  @include font-semi-bold();

  width: 100%;
  font-size: 2rem;
  margin-bottom: 0;

  @include breakpoint-large-up {
    font-size: 2.4rem;
  }
}

.link {
  color: $color-web-link-article-default;
  line-height: 1.6;
}
