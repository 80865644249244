@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";

.countdown-counter-container {
  padding-bottom: $spacing-twentyfour;

  &--redesign {
    padding-bottom: 0;
  }
}

.container {
  display: flex;
  justify-content: center;
}

.headline {
  @include text-18-regular;

  text-align: center;
  margin-bottom: $spacing-sixteen;

  @include breakpoint-medium-up {
    @include text-20-regular;

    margin-bottom: $spacing-eight;
  }
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 6.6rem;
  height: 6.6rem;
  margin: 0 0.6rem;
  padding: 0.9rem 0;
  border-radius: 0.8rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.number {
  font-size: 3rem;
  line-height: 1;
  font-weight: 500;
}

.text {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
}
