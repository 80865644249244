@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

.header-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;
  text-align: center;
  padding: $spacing-twentyfour $spacing-sixteen;
  background-image: url("../../../components/ArticlesList/assets/#{$product}/background.avif");
  background-size: cover;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
    padding: $spacing-fortyeight $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    padding: $spacing-sixtyfour;
  }

  @include breakpoint-larger-up {
    padding: $spacing-sixtyfour;
  }
}

.header {
  @include responsive-title-1;

  color: $color-web-text-headline;
}

.header-description {
  @include text-18-regular;

  @include breakpoint-medium-up {
    @include text-20-regular;

    max-width: 72rem;
    margin: 0 auto;
    padding: 0 $spacing-forty;
  }

  @include breakpoint-large-up {
    max-width: 80rem;
    padding: 0;
  }
}

.breadcrumbs {
  margin: $spacing-twentyfour $spacing-sixteen;

  @include breakpoint-medium-up {
    margin: $spacing-thirtytwo $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    margin: $spacing-forty $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: $spacing-forty auto;
  }
}
