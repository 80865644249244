@import "styles/_theme-vektklubb.scss";
.container {
  position: relative;
  margin-bottom: 2.4rem;
}

.input {
  &.input {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    height: 4.8rem;
    border: none;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
  }

  &::placeholder {
    font-weight: 400;
    color: $color-text-hint;
  }
}

.typeMore {
  position: absolute;
  height: 2.6rem;
  top: 50%;
  margin-top: -1.3rem;
  right: 4rem;
  background-color: $color-web-text-field-validation-background;
  border-radius: 2rem;
  font-size: 1.4rem;
  padding: 0.2rem 1.2rem;
}

.magnifyIcon {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
  stroke: $color-core-grey;
}

.clearButton {
  background-color: white;
  border-color: white;
  fill: black;

  &.clearButton {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    margin-top: -1rem;
    right: 1.2rem;

    &:hover {
      cursor: pointer;
      background-color: $color-core-grey-light-3;
    }
  }
}
