@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

.question {
  background-color: $color-web-background-secondary;
  border-radius: 2rem;
  padding: $spacing-twentyfour $spacing-sixteen;
  display: flex;
  flex-direction: column;
  margin: $spacing-forty 0;

  @include breakpoint-medium-up {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: $spacing-thirtytwo $spacing-twentyfour;
  }
}

.question-title {
  @include text-14-semi-bold-caps;

  padding-bottom: $spacing-eight;
  position: relative;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 4.4rem;
    height: 0.2rem;
    background: $color-text-primary;
  }
}

.question-headline {
  @include text-20;

  margin: 1rem 0 $spacing-sixteen;

  @include breakpoint-medium-up {
    width: 100%;
    margin-bottom: $spacing-twentyfour;
  }
}

.question-image {
  margin-bottom: $spacing-sixteen;
  width: 100%;
  order: -1;

  @include breakpoint-medium-up {
    width: 50%;
    margin-left: $spacing-twentyfour;
    margin-bottom: 0;
    order: 1;
  }

  figure {
    margin: 0;

    @include breakpoint-medium-up {
      border-radius: 3rem;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      padding-top: 75%;
      position: relative;
      width: 100%;
    }
  }

  picture {
    @include breakpoint-medium-up {
      inset: 0;
      position: absolute;
    }
  }

  img {
    height: 100%;
    min-height: auto;
  }

  & + .question-inner {
    @include breakpoint-medium-up {
      width: calc(50% - $spacing-twentyfour);
    }
  }
}

.question-inner {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  @include breakpoint-medium-up {
    flex-grow: 1;
  }
}

.question-answer {
  @include text-16;

  color: $color-web-option-content-default;
  background-color: $color-web-option-background-default;
  border-radius: 1.2rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  gap: $spacing-eight;
  align-items: center;
  text-align: left;
  border: 0.2rem solid transparent;

  &.question-answer-poll {
    border: none;
    overflow: hidden;
    position: relative;

    span {
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: calc(var(--percentage) * 1%);
      top: 0;
      left: 0;
      z-index: 0;
      background-color: $color-web-option-background-selected;
      transform: translateX(-100%);
      transition: transform calc(0.9s) ease;
    }
  }
}

.question-answer-sign {
  background-color: $color-web-option-icon-background-default;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2.4rem;
  line-height: 1;
  height: 2.4rem;
  border-radius: 0.4rem;

  .question-answer-poll--clicked & {
    background-color: $color-web-option-icon-background-selected;
  }
}

.question-answer-number {
  @include text-16-semi-bold;

  color: $color-web-option-icon-content-default;
  align-self: flex-end;

  .question-answer-poll--clicked & {
    color: $color-web-option-icon-content-selected;
  }
}

.question-answer-icon {
  width: 1.6rem;
  display: none;
}

.question-answer-icon-wrong {
  path {
    stroke: $color-web-option-icon-content-wrong;
  }
}

.question-answer-icon-correct {
  path {
    stroke: $color-web-option-icon-content-correct;
  }
}

.question-disclaimer {
  @include text-16-medium;

  margin-top: $spacing-twelve;
  display: flex;
  justify-content: space-between;
  width: 100%;
  order: 2;
}

.question-inner--selected {
  pointer-events: none;

  .question-answer--wrong {
    background-color: transparent;
    color: $color-web-option-content-default;
    border-color: $color-web-option-border-wrong;

    &.question-answer--clicked {
      background-color: $color-web-option-background-wrong;
      color: $color-web-option-content-wrong;
      border-color: transparent;
    }
  }

  .question-answer--correct {
    @include text-16-semi-bold;

    background-color: transparent;
    color: $color-web-option-content-default;
    border-color: $color-web-option-border-correct;

    &.question-answer--clicked {
      background-color: $color-web-option-icon-content-correct;
      color: $color-web-option-content-correct;
      border-color: transparent;
    }
  }

  .question-answer-icon {
    display: block;
  }

  .question-answer-number {
    display: none;
  }

  .question-percentage {
    display: block;
  }

  .question-answer-poll {
    &::before {
      transform: translateX(0);
    }

    .question-answer-number {
      display: block;
    }
  }
}

.question-fact {
  background-color: $color-web-option-background-fact;
  margin-top: 1.2rem;
  padding: $spacing-sixteen;
  border-radius: 1.2rem;

  .bodyText {
    display: inline;
    margin: 0;
    padding: 0;
  }
}

.question-fact-title {
  @include text-16-semi-bold;

  text-transform: capitalize;
}

.question-percentage {
  margin-left: auto;
  display: none;
}

.question-answer-poll--clicked {
  @include font-semi-bold;
}
