@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/variables/breakpoints";
@import "~@styles/utils/variables/z-index";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/transitions";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/underline";

.container {
  background-color: $color-web-footer-ad-background-default;
  background-image: url("./assets/#{$product}/sales-widget-bg.avif");
  background-size: cover;
  text-align: center;
}

.container-inside {
  border-radius: 2rem;
  margin: $spacing-fortyeight $spacing-sixteen;

  @include breakpoint-medium-up {
    margin: $spacing-sixtyfour $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    border-radius: 3rem;
    margin: $spacing-eighty $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: $spacing-eighty auto;
  }
}

.underline {
  @include underline;
}

.box-wrapper {
  max-width: $desktop-max-width;
  margin: 0 auto;
  position: relative;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-twentyfour;
  padding: $spacing-forty $spacing-sixteen $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: $spacing-fortyeight $spacing-sixteen $spacing-sixteen;
  }

  @include breakpoint-large-up {
    padding: $spacing-sixtyfour $spacing-sixteen $spacing-twentyfour;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
  }
}

.title {
  @include responsive-title-4;

  color: inherit;

  @include breakpoint-medium-up {
    max-width: 55rem;
  }
}

.subtitle {
  .is-campaign & {
    @include breakpoint-medium-up {
      font-size: 1.3rem;
    }
  }
}

.cta {
  @include text-16-semi-bold;

  padding: $spacing-twelve $spacing-twentyfour;
  align-self: center;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.25);
  margin-bottom: $spacing-twentyfour;

  @include breakpoint-medium-up {
    @include text-20-semi-bold;

    padding: $spacing-sixteen $spacing-fortyeight;
    margin-bottom: $spacing-thirtytwo;
  }

  @include breakpoint-large-up {
    border-radius: 1.6rem;
    margin-bottom: $spacing-forty;
  }
}

.adjust-for-subtitle {
  @include breakpoint-medium-up {
    bottom: 3.6rem;
  }
}

.campaign-image {
  width: 10rem;

  @include breakpoint-medium-up {
    width: 11.5rem;
    position: absolute;
    left: 0.8rem;
    transform: rotate(-15deg);
    top: -0.8rem;
    z-index: $z-index-first;
  }
}

.footer-ad-wrapper {
  border-top: 0.1rem solid $color-core-grey-light-2;
  width: calc(100% - 2 * $spacing-sixteen);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-sixteen;
  padding: $spacing-twentyfour 0 $spacing-fortyeight;
  margin: 0 auto;

  @include breakpoint-medium-up {
    width: calc(100% - 2 * $spacing-twentyfour);
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    width: calc(100% - 2 * $spacing-fiftysix);
    padding: $spacing-thirtytwo 0 $spacing-sixtyfour;
  }

  @include breakpoint-larger-up {
    width: 100%;
    padding: $spacing-thirtytwo 0 $spacing-sixtyfour;
  }
}

.footer-ad-text {
  text-align: start;

  @include breakpoint-medium-up {
    width: 50%;
  }
}

.footerAdIcon {
  width: 15rem;

  @include breakpoint-medium-up {
    width: 19.6rem;
  }
}
