@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";

.input.input {
  width: 100%;
}

.inputSmall {
  max-width: 30%;
}

.container {
  padding: 1.6rem;

  @include breakpoint-medium-up {
    padding: 2.4rem;
  }
}
