@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/breadcrumbs";
@import "~@styles/utils/mixins/typography";

.container {
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 $spacing-sixteen;
  min-height: 100vh;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix;
    min-height: 80vh;
  }
}

.container-inner {
  padding-bottom: $spacing-sixteen;

  @include breakpoint-large-up {
    width: 83%;
    max-width: 80rem;
    margin: 0 auto;
    padding-bottom: 5rem;
  }

  a {
    color: $color-web-link-article-default;
  }
}

.breadcrumbs {
  @include breadcrumbs;
}

.title {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3.2rem;
  margin: 1.7rem 0 2.5rem;
  padding: 0 2rem;

  @include breakpoint-medium-up {
    font-size: 4.2rem;
    line-height: 5rem;
    padding: 0 2.5rem;
  }

  @include breakpoint-large-up {
    padding: 0;
  }
}

.imageWrapper {
  margin: 0 2rem 1.2rem;

  @include breakpoint-medium-up {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @include breakpoint-large-up {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    border-radius: 2rem;
    min-height: 42vw;

    @include breakpoint-large-up {
      min-height: 320px;
    }
  }
}

.image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  opacity: 0;

  &--top {
    opacity: 1;
  }
}

.imageByline {
  font-size: 1.1rem;
  line-height: 1.45;
  color: $color-grey-medium;
  padding: 0.8rem 1rem 0;

  @include breakpoint-medium-up {
    font-size: 1.2rem;
  }
}

.topLeadText {
  font-size: 1.5rem;
  line-height: 2.1rem;
  margin: 1rem 0;
  font-weight: 600;
  padding: 0 2rem;

  @include breakpoint-medium-up {
    font-size: 2rem;
    line-height: 3rem;
    padding: 0 2.5rem;
  }

  @include breakpoint-large-up {
    padding: 0;
  }
}

.bodyText {
  font-size: 1.5rem;
  line-height: 2.1rem;
  margin: 1rem 0;
  padding: 0 2rem;

  @include breakpoint-medium-up {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 0 2.5rem;
  }

  @include breakpoint-large-up {
    padding: 0;
  }
}

.bodySubtitle {
  @include title-20;

  margin-top: 2.8rem;
  margin-bottom: 0.5em;
  padding: 0 2rem;

  @include breakpoint-medium-up {
    padding: 0 2.5rem;
  }

  @include breakpoint-large-up {
    padding: 0;
  }
}

@mixin factBoxBase {
  font-weight: 400;
  background-color: $color-web-background-secondary;
  color: $color-text-primary;
  padding: 2rem;
  margin: 1rem 2rem;
  border-radius: 2rem;

  h2 {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.4rem;
    margin: 0 0 1rem;
  }

  ul,
  ol {
    padding: 0 1.8rem;
  }
}

.summary {
  @include factBoxBase;

  margin: $spacing-thirtytwo 2rem;
  padding: $spacing-twentyfour $spacing-sixteen;
  border-radius: 2rem;

  ul {
    font-size: $spacing-sixteen;
    line-height: 1.5;
    margin: $spacing-eight 0;
  }

  li {
    margin-top: $spacing-eight;
  }

  .factboxTextBlock {
    font-size: $spacing-sixteen;
  }

  @include breakpoint-medium-up {
    margin: $spacing-thirtytwo 2.5rem;
    padding: $spacing-thirtytwo $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    margin: $spacing-forty 0;
  }
}

.factbox {
  @include factBoxBase;

  position: relative;

  @include breakpoint-medium-up {
    max-width: 40%;
    display: inline-block;
    float: right;
    margin: 0 2rem 2rem;
  }

  @include breakpoint-large-up {
    border-radius: 0.8rem;
    margin: 0 0 2rem 2rem;
  }
}

.factboxTextBlock {
  margin: $spacing-eight 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.factboxDisclaimerBlock {
  font-size: 1.4rem;
  font-style: italic;
  margin-top: $spacing-eight;

  mark {
    background-color: $color-web-background-secondary;
    color: $color-text-primary;
    text-decoration: underline;
  }
}

.authors {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.1rem;
  padding-top: 1.4rem;
  border-top: 0.1rem solid $color-web-divider-primary;
  clear: both;
  margin: 1.4rem 2rem 0;

  @include breakpoint-medium-up {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 2rem 2.5rem 0;
  }

  @include breakpoint-large-up {
    margin-left: 0;
    margin-right: 0;
  }

  &TitleByline {
    border: 0;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
}

.videoWrapper {
  aspect-ratio: 16 / 9;
  position: relative;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.factboxBody {
  @include breakpoint-medium-up {
    font-size: 1.4rem;
    line-height: 2rem;
    position: relative;
  }
}

.titleByline {
  color: $color-grey-dark;
  padding: 0 2rem;
  font-size: 1.4rem;
  line-height: 2.1rem;

  @include breakpoint-medium-up {
    padding: 0 2.5rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  @include breakpoint-large-up {
    padding: 0;
  }
}

.challenge-button {
  font-size: 20px;
  line-height: 26px;
  padding: 16px 35px;
  margin-left: 2rem;

  @include breakpoint-medium-up {
    margin-left: 2.5rem;
  }

  @include breakpoint-large-up {
    margin-left: 0;
  }
}

.iframeEmbed {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 1rem;
}

.disclaimer {
  margin: 1.6rem 2rem;

  @include breakpoint-medium-up {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @include breakpoint-large-up {
    margin-left: 0;
    margin-right: 0;
  }
}
