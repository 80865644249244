@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";

.container {
  padding: 3rem 1.6rem 2.4rem;
  text-align: center;
  margin: 0 auto;

  @include breakpoint-small-up {
    padding: 3rem $spacing-sixteen $spacing-twentyfour;
  }

  @include breakpoint-medium-up {
    padding: 3rem $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: 4rem 6.4rem 3rem;
    max-width: 117rem;
  }

  &--redesign {
    padding-top: $spacing-forty;

    @include breakpoint-medium-up {
      padding-top: $spacing-sixtyfour;
      padding-bottom: 0;
    }

    @include breakpoint-large-up {
      padding-top: $spacing-eighty;
    }
  }
}

.container-reset {
  padding: 0;
  max-width: none;
}

.wrapper {
  display: flex;
  gap: $spacing-sixteen;

  @include breakpoint-medium-up {
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;
  }
}

.testimonial {
  width: 100%;
  text-align: center;
  display: none;
  color: $color-text-primary;
  padding: 0;

  &:first-child {
    display: block;
  }

  &:nth-child(-n + 2) {
    width: 50%;
    display: block;
  }

  @include breakpoint-medium-up {
    &:nth-child(-n + 3) {
      width: calc(100% / 3);
      display: block;
    }
  }

  @include breakpoint-large-up {
    &:nth-child(-n + 4) {
      width: 25%;
      display: block;
    }
  }
}

.image {
  margin: 0 auto;
  border-radius: 2rem;
  width: 100%;
}

.quote {
  @include text-14;

  max-width: 280px;
  margin: 0 auto;

  @include breakpoint-medium-up {
    @include text-16;
  }
}

.btn {
  margin-top: 3rem;

  &--redesign {
    margin-bottom: 0;
  }
}

.title {
  @include responsive-title-4;

  margin-bottom: $spacing-sixteen;

  &::before {
    @include underline;

    content: "";
    display: block;
    margin: 0 auto $spacing-eight;

    @include breakpoint-medium-up {
      margin-bottom: $spacing-sixteen;
    }

    @include breakpoint-large-up {
      margin-bottom: $spacing-twentyfour;
    }
  }

  @include breakpoint-medium-up {
    margin-bottom: $spacing-thirtytwo;
  }
}

.divider {
  border: 0;
  border-bottom: 0.1rem;
  border-color: $color-web-divider-secondary;
  border-style: solid;
  margin: 0.4rem 1.6rem 0;

  @include breakpoint-medium-up {
    margin: 1.6rem 2.4rem 0;
  }

  @include breakpoint-large-up {
    margin-top: 3.6rem;
  }
}

.footnote {
  @include font-regular-italic;

  margin: 2.4rem auto 0;
  font-size: 1.4rem;
  line-height: 2rem;
  max-width: 80rem;
  text-align: center;
  padding: 0 2rem;
}

.header {
  display: flex;
  flex-direction: column;
  margin: $spacing-eight 0;
}

.name {
  @include text-12-semi-bold;

  letter-spacing: 0.24rem;
  text-transform: uppercase;
}

.tag {
  @include responsive-title-5;
}
