@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/images";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/underline";

.underline {
  @include underline;
}

.container {
  padding: 0 $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: 0;

    &:nth-child(odd) {
      .featureWrapper {
        flex-direction: row;

        .mainFigureWrapper {
          margin-right: $spacing-sixteen;
        }

        .content {
          margin-left: $spacing-sixteen;
        }
      }
    }

    &:nth-child(even) {
      .featureWrapper {
        flex-direction: row-reverse;

        .mainFigureWrapper {
          margin-left: $spacing-sixteen;
        }

        .content {
          margin-right: $spacing-sixteen;
        }
      }
    }
  }
}

.featureWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include breakpoint-large-up {
    align-items: flex-start;
  }
}

.mainFigureWrapper {
  @include breakpoint-large-up {
    width: 50%;
  }
}

.mainFigure {
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
  width: 100%;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    width: 50%;
    margin: auto 0;
  }
}

.title {
  @include responsive-title-4;

  margin: 0;
}

.description {
  @include text-16-regular;

  margin-bottom: $spacing-twentyfour;

  @include breakpoint-large-up {
    @include text-20-regular;

    margin: 0;
  }
}
