@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/images";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/underline";

.underline {
  @include underline;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
  }
}

.backgroundContainer {
  background-color: $color-web-background-section;
}

.container {
  padding: $spacing-fortyeight $spacing-sixteen;
  display: flex;
  flex-direction: column;
  gap: $spacing-twentyfour;

  @include breakpoint-medium-up {
    padding: $spacing-sixtyfour $spacing-twentyfour;
    gap: $spacing-forty;
  }

  @include breakpoint-large-up {
    gap: $spacing-fortyeight;
    padding: $spacing-eighty $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: 0 auto;
    padding: $spacing-eighty 0;
  }
}

.featureWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include breakpoint-large-up {
    align-items: flex-start;
  }
}

.mainFigureWrapper {
  @include breakpoint-large-up {
    width: 50%;
  }
}

.mainFigure {
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
  width: 100%;
  margin: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }
}

.title {
  @include responsive-title-4;

  margin: 0;
}

.quoteTitle {
  @include responsive-title-6;

  margin: 0;
}

.description {
  @include text-16-regular;

  margin-bottom: $spacing-twentyfour;

  @include breakpoint-large-up {
    @include text-20-regular;

    margin: 0;
  }
}

.author {
  @include text-12-semi-bold-caps;
}

.quotesWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-sixteen;

  @include breakpoint-medium-up {
    gap: $spacing-thirtytwo;
  }

  @include breakpoint-large-up {
    flex-direction: row;
  }
}

.quoteWrapper {
  background-color: $color-core-white;
  border-radius: 2rem;
  padding: $spacing-twentyfour $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: $spacing-thirtytwo $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    width: 33%;
  }
}

.iconWrapper {
  display: flex;
  margin-bottom: $spacing-sixteen;
  gap: $spacing-eight;
}

.icon {
  path {
    fill: $color-illustration-large-background;
  }
}

.badgesWrapper {
  display: flex;
  gap: $spacing-sixteen;
  align-items: center;
  justify-content: center;

  @include breakpoint-medium-up {
    gap: $spacing-thirtytwo;
  }

  & > img {
    height: 100%;
  }
}
