@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";

p.important {
  font-size: 1.3rem;
  margin-top: 2rem;
  color: $color-grey-dark;
}

.container {
  padding: 1.6rem;

  & > div {
    margin-bottom: 1.6rem;
  }

  @include breakpoint-medium-up {
    padding: 2.4rem;
  }
}
