@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/variables/spacing";

.breadcrumbs-slash {
  margin: 0 $spacing-eight;

  span:last-child & {
    display: none;
  }
}
