@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";

.modal {
  min-height: auto;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  max-width: 60rem;
  margin: 0 auto;
  width: auto;
  display: flex;
  flex-direction: column;

  @include breakpoint-small-up {
    margin: 3.2rem auto;
    width: calc(100vw - 6.4rem);
    border-radius: 2rem;
    overflow: hidden;
    max-height: calc(100vh - 6.4rem);
  }

  @include breakpoint-medium-up {
    margin: 3.2rem auto;
    width: calc(100vw - 9.6rem);
    max-height: calc(100vh - 9.6rem);
  }
}
