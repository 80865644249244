@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";

.container {
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 $spacing-sixteen $spacing-fortyeight;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix $spacing-eighty;
  }
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;
  text-align: center;
  padding: $spacing-twentyfour $spacing-sixteen;
  background-image: url("../../../components/ArticlesList/assets/#{$product}/background.avif");
  background-size: cover;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
    padding: $spacing-fortyeight $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    padding: $spacing-sixtyfour;
  }

  @include breakpoint-larger-up {
    padding: $spacing-sixtyfour;
  }
}

.breadcrumbs {
  margin: $spacing-twentyfour 0;

  @include breakpoint-medium-up {
    margin: $spacing-thirtytwo 0;
  }

  @include breakpoint-large-up {
    margin: $spacing-forty 0;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: $spacing-forty auto;
  }
}

.bmiRanges {
  text-align: center;
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

.header {
  @include responsive-title-1;

  color: $color-web-text-headline;
}

.header-description {
  @include text-18-regular;

  @include breakpoint-medium-up {
    @include text-20-regular;

    max-width: 72rem;
    margin: 0 auto;
    padding: 0 $spacing-forty;
  }

  @include breakpoint-large-up {
    max-width: 80rem;
    padding: 0;
  }
}

.paragraphsContainer {
  padding: 0;
  margin: $spacing-twentyfour auto 0;

  @include breakpoint-large-up {
    max-width: 79.2rem;
  }
}

.subheader {
  @include responsive-title-6;

  padding: $spacing-sixteen 0 0;
}

.paragraph {
  padding-bottom: $spacing-sixteen;
  margin: 0;
}
