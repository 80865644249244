@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";

.fieldset {
  display: flex;
  flex-direction: column;

  @include breakpoint-medium-up {
    & > * {
      margin-bottom: 0;
    }
  }

  @include breakpoint-large-up {
    & > * {
      margin-bottom: 1.6rem;
    }
  }

  @include breakpoint-medium-only {
    flex-direction: row;
  }
}

.container {
  padding: 1.6rem;

  @include breakpoint-medium-up {
    padding: 2.4rem;
  }
}

.errorLabel {
  white-space: initial;
}
