@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";
@import "~@styles/utils/mixins/breadcrumbs";

@mixin container-title {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }
}

.underline {
  @include underline;
}

.container {
  min-height: 100vh;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;
  text-align: center;
  padding: $spacing-twentyfour $spacing-sixteen;
  background-image: url("../ArticlesList/assets/#{$product}/background.avif");
  background-size: cover;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
    padding: $spacing-fortyeight $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    padding: $spacing-sixtyfour;
  }

  @include breakpoint-larger-up {
    padding: $spacing-sixtyfour;
  }
}

.breadcrumbs {
  @include breadcrumbs;
}

.header {
  @include responsive-title-1;

  color: $color-web-text-headline;
}

.header-description {
  @include text-18-regular;

  @include breakpoint-medium-up {
    @include text-20-regular;

    max-width: 72rem;
    margin: 0 auto;
    padding: 0 $spacing-forty;
  }

  @include breakpoint-large-up {
    max-width: 80rem;
    padding: 0;
  }
}

.sections-list-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-fortyeight;

  @include breakpoint-medium-up {
    gap: $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-eighty;
  }
}

.sections-container {
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 $spacing-sixteen $spacing-fortyeight;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour $spacing-sixtyfour;
  }

  @include breakpoint-large-up {
    padding: 0 $spacing-fiftysix $spacing-eighty;
  }

  &--raw-section-view {
    padding: 0;
  }
}

.footer-container {
  background-color: $color-web-background-section;
}

.footer-content-container {
  max-width: 128rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: $spacing-twentyfour;
  padding: $spacing-fortyeight $spacing-sixteen;
  text-align: start;

  @include breakpoint-medium-up {
    padding: $spacing-sixtyfour $spacing-twentyfour;
    flex-direction: row;
  }

  @include breakpoint-large-up {
    padding: $spacing-eighty $spacing-fiftysix;
    gap: $spacing-thirtytwo;
  }
}

.footer-headline {
  @include responsive-title-4;
}

.footer-description-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sixteen;

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
  }
}

.footer-paragraph {
  @include text-16-regular;
}

.footer-header-container {
  @include container-title;

  @include breakpoint-medium-up {
    min-width: 30%;
  }
}
