@import "styles/_theme-vektklubb.scss";
.container {
  display: flex;
  flex-wrap: wrap;

  section.leftSection {
    margin: 2rem;
    width: calc(80% - 4rem);
  }

  section.rightSection {
    margin: 2rem;
    width: calc(20% - 4rem);
  }

  button {
    width: 30rem;
  }

  .selectAllSwitch {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 30rem;
    padding: 1rem;
    border-radius: 1rem;
  }
}

:global #preloader {
  width: 5rem;
  margin: auto;
}
