@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/images";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/lists";
@import "~@styles/utils/mixins/underline";

.underline {
  @include underline;
}

.container {
  padding: $spacing-fortyeight $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: $spacing-sixtyfour $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: $spacing-eighty $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    display: flex;
    justify-content: center;
    padding: $spacing-eighty 0;
  }

  &:nth-child(3) {
    padding-top: 0;
  }

  &:nth-child(7) {
    padding-top: 0;
  }

  &:nth-child(even) {
    background-color: $color-web-background-section;

    @include breakpoint-large-up {
      .contentSectionWrapper {
        flex-direction: row-reverse;
      }
    }
  }

  &:nth-child(odd) {
    @include breakpoint-large-up {
      .contentSectionWrapper {
        flex-direction: row;
      }
    }
  }
}

.plainBackgroundContainer {
  &:nth-child(even) {
    background-color: $color-core-white;
  }
}

.swapOrderContainer {
  &:nth-child(odd) {
    background-color: $color-web-background-section;
    padding-top: $spacing-fortyeight;

    @include breakpoint-medium-up {
      padding-top: $spacing-sixtyfour;
    }

    @include breakpoint-large-up {
      padding-top: $spacing-eighty;

      .contentSectionWrapper {
        flex-direction: row-reverse;
      }
    }
  }
}

.contentSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-twentyfour;

  @include breakpoint-medium-up {
    gap: $spacing-thirtytwo;
  }

  @include breakpoint-large-up {
    flex-direction: row;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
  }
}

.featureWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include breakpoint-large-up {
    align-items: flex-start;
  }
}

.mainFigureWrapper {
  @include breakpoint-large-up {
    width: 60%;
  }

  @include breakpoint-large-up {
    width: 70%;
  }
}

.mainFigure {
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
  width: 100%;
  margin: 0;

  @include breakpoint-large-up {
    border-radius: 4rem;
    max-width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    width: 40%;
    justify-content: center;
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    width: 30%;
  }
}

.title {
  @include responsive-title-4;

  margin: 0;
}

.description {
  @include text-16-regular;

  display: none;

  @include breakpoint-large-up {
    @include text-20-regular;

    display: block;
  }
}

.description-mobile {
  display: block;

  @include breakpoint-large-up {
    display: none;
  }
}

.list {
  @include list-reset;

  display: none;

  @include breakpoint-large-up {
    display: flex;
    flex-direction: column;
    gap: $spacing-sixteen;
  }
}

.list-mobile {
  display: flex;
  flex-direction: column;
  gap: $spacing-sixteen;

  @include breakpoint-large-up {
    display: none;
  }
}

.list-item {
  @include text-16-medium;

  display: flex;
  align-items: center;
  gap: $spacing-sixteen;
}

.icon {
  min-width: 2.4rem;

  path {
    fill: $color-icon-check;
  }
}

.equalContainersContent {
  @include breakpoint-large-up {
    width: 50%;
  }
}

.equalContainersFigure {
  @include breakpoint-large-up {
    width: 50%;
  }
}
