@mixin button-reset {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

@mixin button-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.8;
}
