@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/variables/z-index";
@import "~@styles/utils/mixins/accessibility";

.visually-hidden {
  @include visually-hidden;
}

.subheader {
  z-index: $z-index-sixth;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.container {
  :global {
    .sch-datacontroller {
      background-color: $color-web-background-secondary;
    }

    .sch-datacontroller__wrapper {
      position: relative;
      margin-top: auto;
      background-color: $color-web-background-secondary;
      padding: 8px 0;
    }

    .sch-datacontroller__content {
      justify-content: center;
      padding: 1.5rem;
      position: relative;

      @include breakpoint-larger-up {
        width: 1170px;
        margin: 0 auto;
        padding: 0;
      }
    }

    .sch-datacontroller__logo {
      max-width: 70px;

      rect {
        display: none;
      }
    }

    .sch-datacontroller__read-more {
      color: $color-web-link-default;
    }
  }
}

@media print {
  .container {
    display: none;
  }
}
