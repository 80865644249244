@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/buttons";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";

.input.input {
  width: 100%;
}

.selectInput {
  margin-top: $spacing-eight;
}

.selectLabel {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  font-weight: 600;
  width: 15.8rem;
  margin-right: $spacing-sixteen;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-twentyfour;
  }

  &:last-child {
    margin-bottom: 0;

    @include breakpoint-medium-up {
      margin-bottom: $spacing-twentyfour;
    }
  }

  & > div {
    margin-bottom: 0;
  }
}

.selectLabelUser {
  order: -1;
  margin: 0 1.6rem 1.6rem 0;

  &:last-child {
    margin-bottom: 1.6rem;
  }
}

.fieldset {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include breakpoint-medium-up {
    margin-bottom: 0;
  }
}

.fieldsetUser {
  flex-direction: column;

  @include breakpoint-medium-up {
    flex-direction: row;
  }
}

.descriptivePackageSize {
  margin-top: $spacing-sixteen;

  @include breakpoint-medium-up {
    margin-top: 0;
    margin-bottom: $spacing-twentyfour;
  }
}

.gramsPerCustomUnit {
  & > div {
    white-space: nowrap;

    &:nth-child(3) {
      @include breakpoint-medium-up {
        margin-bottom: $spacing-twentyfour;
      }
    }
  }
}

.iconClassName {
  margin-top: 0;
}

.contentClassName {
  padding: $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: $spacing-twentyfour;
    padding-bottom: 0;
  }
}

.addUnitButton {
  @include button-reset;

  display: flex;
  align-items: center;
  margin-top: $spacing-eight;

  @include breakpoint-small-up {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.ctaInner {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid $color-fast-log-accent;
  border-radius: 50%;
  fill: $color-fast-log-accent;
  float: right;
  stroke-width: 2;
  stroke: $color-fast-log-accent;
  width: 2.2rem;
  height: 2.2rem;

  button:hover & {
    stroke: $color-fast-log-background-checked-hovered;
    border-color: $color-fast-log-background-checked-hovered;
  }
}

.ctaIcon {
  width: 1rem;
  height: 1rem;
}

.ctaText {
  color: $color-web-button-log-text-default;
  padding-right: 1rem;

  button:hover & {
    text-decoration: underline;
  }
}

.customUnitContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: $spacing-sixteen;
  margin-bottom: $spacing-eight;
  border-bottom: 0.1rem solid $color-core-grey-light-3;

  @include breakpoint-small-up {
    align-items: center;
    margin-bottom: $spacing-sixteen;
    padding-bottom: $spacing-eight;
  }
}

.customUnitContainerUser {
  border: none;
  margin: 0;
  padding: 0;
}

.clearButton {
  background-color: white;
  border-color: white;
  fill: black;

  &.clearButton {
    width: 2rem;
    height: 2rem;

    @include breakpoint-small-up {
      margin-top: 1.2rem;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-core-grey-light-3;
    }
  }
}

.addUnit {
  padding-bottom: $spacing-sixteen;
  border-bottom: 0.1rem solid $color-core-grey-light-3;
}

.customUnitContainerFields {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint-small-up {
    flex-direction: row;
  }
}

.customUnitContainerFieldsUser {
  flex-direction: column;

  @include breakpoint-medium-up {
    flex-direction: row;
  }
}

.customUnitNameLabel {
  @include breakpoint-small-up {
    width: 22rem;
  }

  @include breakpoint-medium-up {
    width: 32rem;
  }
}

.switchContainer {
  & > label {
    @include font-semi-bold;

    padding-right: 0;
    font-size: 1.3rem;
    line-height: 1.5;
    margin: 0;

    &::before {
      top: 3.2rem;
    }

    &::after {
      top: 3.3rem;
    }
  }
}
