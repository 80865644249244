@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";

.container {
  display: flex;
  position: relative;
  margin: 1rem 2rem;

  @include breakpoint-large-up {
    margin: 1rem 0;
  }
}

.boxWrapper {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  align-items: center;
  cursor: pointer;

  @include breakpoint-large-up {
    margin: 0 auto;
    max-width: 900px;
  }

  @include breakpoint-larger-up {
    max-width: 1060px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem 1.5rem;
  background-color: white;
  box-shadow: 0 0.2rem 0.4rem 0.1rem rgba(0, 0, 0, 0.17);
  border-radius: 0.8rem;

  &.grey {
    background-color: $color-web-background-secondary;
  }
}

.content {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2rem 0;
  color: $color-text-primary;

  @include breakpoint-medium-up {
    flex-direction: row;
    text-align: left;
  }

  &.is-campaign {
    @include breakpoint-medium-up {
      padding-left: 13rem;
    }
  }

  &.vertical {
    @include breakpoint-medium-up {
      flex-direction: column;
    }
  }
}

.widgetInner {
  .vertical & {
    margin-bottom: 3rem;
  }
}

.header {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 4rem;
  margin: 0 0 1rem;

  @include breakpoint-medium-up {
    font-size: 4rem;
  }

  @include breakpoint-large-up {
    line-height: 4.8rem;
  }

  .is-campaign & {
    @include breakpoint-medium-up {
      font-size: 3.2rem;
      line-height: 1;
    }

    @include breakpoint-large-up {
      font-size: 3.6rem;
    }
  }

  .vertical & {
    margin-bottom: 0;
    text-align: center;
  }

  &.small-font {
    @include breakpoint-medium-up {
      font-size: 3rem;
    }

    @include breakpoint-large-up {
      font-size: 3.4rem;
    }
  }
}

.body {
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;

  @include breakpoint-medium-up {
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 600;
  }

  p {
    font-size: inherit;
  }

  .is-campaign & {
    @include breakpoint-medium-up {
      font-size: 1.2rem;
    }

    @include breakpoint-large-up {
      font-size: 1.3rem;
    }
  }

  .vertical & {
    text-align: center;
    font-weight: 600;
    font-size: 2.1rem;
    margin: 2rem 0 0;
  }
}

.cta {
  margin: 0 0 1rem;
  font-size: 2rem;
  line-height: 2.6rem;
  padding: 1.6rem 3.2rem;
  width: 100%;
  flex-shrink: 0;

  @include breakpoint-medium-up {
    width: auto;
  }
}

.footNote {
  font-size: 1.1rem;
  margin-bottom: 1rem;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.campaign-image {
  width: 10rem;
  align-self: center;

  @include breakpoint-medium-up {
    width: 11.5rem;
    position: absolute;
    left: 2rem;
    transform: rotate(-15deg);
    top: 0.5rem;
  }
}
